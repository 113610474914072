// Action Types
export const Types = {
    LIST_TEMPLATE: 'LIST_TEMPLATE',
    CREATE_TEMPLATE: 'CREATE_TEMPLATE',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
    DELETE_TEMPLATE: 'DELETE_TEMPLATE',
    LIST_TEMPLATES_PAGE: 'LIST_TEMPLATES_PAGE',
    LIST_TEMPLATES_SUCCESS_PAGE: 'LIST_TEMPLATES_SUCCESS_PAGE'
}

// Reducer
const INITIAL_STATE = {
    templates: []
}

export default function reducer(state = INITIAL_STATE, action) {
    // console.log("=>",action.type);
    if(action.type === Types.LIST_TEMPLATES_SUCCESS_PAGE){
        return {
            ...state,
            templates: action.payload
        }
    }else if(action.type === Types.LIST_TEMPLATES_SUCCESS_PAGE) {
        return {
            ...state,
            templates: action.payload
        }
    } else{
        return state;
    }
}

// Action Creators
export const listRoles = () => {
    return {
        type: Types.LIST_TEMPLATE
    }
}

export const listTemplateSuccess = (permission) => {
    return {
        type: Types.LIST_TEMPLATES_SUCCESS_PAGE,
        payload: permission
    }
}

export const listTemplates = () => {
    return {
        type: Types.LIST_TEMPLATES_PAGE
    }
}

export const listTemplatesSuccess = (templates) => {
    return {
        type: Types.LIST_TEMPLATES_SUCCESS_PAGE,
        payload: templates
    }
}

export const createTemplate = (role) => {
    return {
        type: Types.CREATE_TEMPLATE,
        payload: role
    }
}

export const updateTemplate = (role) => {
    return {
        type: Types.UPDATE_TEMPLATE,
        payload: role
    }
}

export const deleteTemplate = (id) => {
    return {
        type: Types.DELETE_TEMPLATE,
        payload: id
    }
}
