import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Template {
    static async list() {
        try {
            const response = await Api.get('/template');
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async create(data) {
        try {

            const response = await Api.post('/template', data);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async remove(id) {
        try {
            const response = await Api.delete(`/template/${id}`);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }

    static async update(data) {
        try {
            const response = await Api.put(`/template/${data._id}`, data);
            return response;
        } catch (err) {
            throw handleError(err);
        }
    }
}


export const listAll = async () => await Template.list();
export const list = async () => await Template.list();
export const createData = async (data) => await Template.create(data.payload);
export const updateData = async (data) => await Template.update(data);
export const removeData = async (id) => await Template.remove(id);

export default Template;
