import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {listAll, list, createData, updateData, removeData } from "../../services/Template";
import { Types, listTemplateSuccess, listTemplatesSuccess } from "../reducers/Template"
import { errorMessage } from "../actions/FeedbackMessage"
import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";


export function* listSaga() {
    yield takeLatest(Types.LIST_TEMPLATE, function* list() {
        try {
            yield put(componentLoading(true))

            const roles = yield call(listAll)

            yield put(componentLoading(false))
            yield put(listTemplateSuccess(roles))
        } catch (error) {
            yield put(componentLoading(false))
            yield put(errorMessage(error))
        }
    }
    );
}

export function* listTemplatesSaga() {
    yield takeLatest(Types.LIST_TEMPLATES_PAGE, function* listAllTemplates() {

        try{
            //GET ALL TEMPLATES
            const templates = yield call(list)
            //PUT ALL TEMPLATES IN REDUX
            yield put(listTemplatesSuccess(templates))
        }catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* createSaga() {
    yield takeLatest(Types.CREATE_TEMPLATE, function* (payload) {
        try {
            yield put(buttonLoading(true))

            yield call(createData, payload)

            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            const roles = yield call(listAll)
            //PUT ALL TEMPLATES IN REDUX
            yield put(listTemplateSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            yield put(errorMessage(error))
        }
    });
}

export function* updateSaga() {
    yield takeLatest(Types.UPDATE_TEMPLATE, function* update({payload}) {
        try {
            yield put(buttonLoading(true))

            yield call(updateData, payload)

            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            const roles = yield call(listAll)
            yield put(listTemplateSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            yield put(errorMessage(error))
        }
    });
}

export function* deleteSaga() {
    yield takeLatest(Types.DELETE_TEMPLATE, function* remove({payload}) {
        try {
            yield put(buttonLoading(true))

            yield call(removeData, payload)

            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            const roles = yield call(listAll)
            yield put(listTemplateSuccess(roles))
        } catch (error) {
            yield put(buttonLoading(false))
            yield put(toggleModal(false))

            yield put(errorMessage(error))
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(listTemplatesSaga),
        fork(createSaga),
        fork(updateSaga),
        fork(deleteSaga)
    ]);
}
